<template>
  <div class="camp-index">
    <el-header height="104px">
      <Header :typeFu="3"></Header>
      <div class="bg-top">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            :to="{
              path: '/signUp/examInfo?code=' + $store.state.indexIdentity,
            }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>个人信息</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-header>

    <div class="com-container">
      <div class="index-container">
        <div class="camp_left" ref="leftBoxFu">
          <ul
            class="camp_left_box"
            ref="leftBox"
            :class="{
              'is-fixed': isPosition == 1,
              'is-absolute': isPosition == 2,
            }"
          >
            <li
              v-for="(item, index) in tabs"
              :key="index"
              :class="{ active: campindex_type === index }"
              @click="ToSignUp(index)"
            >
              <img :src="item.url" />
              {{ item.desc }}
            </li>
          </ul>
        </div>
        <div class="camp_right" ref="rightBox">
          <personalInfo v-if="this.campindex_type == 0"> </personalInfo>
          <!-- <password v-if="this.campindex_type == 1"> </password> -->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
/* eslint-disable */
import Header from "@/components/Header.vue";
import Footer from "@/components/index/Footer.vue";
import svg1 from "@/assets/img/signUp/icon.svg";
import svg2 from "@/assets/img/signUp/icon-1.svg";
import personalInfo from "v/index/Info/personalInfo";
import password from "v/index/Info/password";

export default {
  name: "Info",
  components: {
    Header,
    Footer,
    personalInfo,
    password,
  },
  data() {
    return {
      index: 0,
      isPosition: 0, // 左侧box是否浮动
      pageYOffset: 0, // 左侧box浮动时，滚动的高度
      scorllHeight: 0, // 最大滚动高度
      tabs: [
        {
          id: "0",
          desc: "个人信息",
          icon: "icon-icon1",
          url: svg1,
        },
        // {
        //   id: "1",
        //   desc: "修改密码",
        //   icon: "icon-icon-1",
        //   url: svg2,
        // },
      ],
      campindex_type: 0,
      index_status: "",
    };
  },
  watch: {},
  created() {
    this.ToSignUp(this.index);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollX, true);
    window.addEventListener("resize", this.onResize, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollX, true);
    window.removeEventListener("resize", this.onResize, true);
  },
  methods: {
    ToSignUp(index) {
      let code = this.$store.state.indexIdentity;
      this.campindex_type = index;
    },
    onResize() {
      const refLeft = this.$refs["leftBox"];
      const leftBoxFu = this.$refs["leftBoxFu"];
      refLeft.style.width = leftBoxFu.offsetWidth + "px";
    },
    // 页面滚动事件
    handleScrollX() {
      const top = document.documentElement.scrollTop || document.body.scrollTop;
      const refLeft = this.$refs["leftBox"];
      const leftBoxFu = this.$refs["leftBoxFu"];
      const refRight = this.$refs["rightBox"];
      if (top > 0) {
        if (this.isPosition == 0) {
          this.scorllHeight = refRight.offsetHeight - refLeft.offsetHeight;
          refLeft.style.width = leftBoxFu.offsetWidth + "px";
        }
        this.isPosition = 1;
      } else {
        if ((this.isPosition = 1)) {
          this.isPosition = 0;
        }
      }
      if (top >= this.scorllHeight) {
        if (this.isPosition == 1) {
          this.isPosition = 2;
        }
      } else if (top < this.scorllHeight) {
        if (this.isPosition == 2) {
          this.isPosition = 1;
          refLeft.style.width = leftBoxFu.offsetWidth + "px";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "a/scss/common";
@import "a/scss/index";
@import "a/style";
@font-face {
  font-family: "icomoon";
  src: url("../../../assets/fonts/icomoon.eot?dcunb6");
  src: url("../../../assets/fonts/icomoon.eot?dcunb6#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/icomoon.ttf?dcunb6") format("truetype"),
    url("../../../assets/fonts/icomoon.woff?dcunb6") format("woff"),
    url("../../../assets/fonts/icomoon.svg?dcunb6#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.camp_left i {
  font-family: "icomoon";
}
.camp-index {
  background: #f8f8f8;

  .el-header {
    position: fixed;
    width: 100%;
    padding: 0;
    background-color: #ffffff;
    z-index: 2000; //消息提示框z-index=2019

    .bg-top {
      height: 24px;
      background-color: #f8f8f8;
      padding: 30px 0 0 0;
      padding-left: 50px;
    }
  }

  // .com-container {
  //   height: calc(100% - 100px);
  // }
  .index-container {
    padding: 130px 0 70px 0;
    display: flex;
    flex-flow: row;

    .camp_left {
      position: relative;
      width: 22%;
      max-width: 280px;
      min-width: 200px;
      height: 300px;

      background-color: #ffffff;
      border-radius: 8px;
      padding: 20px 0;
      margin-right: 25px;
      .camp_left_box {
        padding-inline-start: 0 !important;
      }
      &.is-fixed {
        position: fixed;
        top: 104px;
        /*width: 14.4%;*/
        max-width: 280px;
        min-width: 216px;
      }

      &.is-absolute {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    li {
      position: relative;
      padding: 0 23px 0 51px;
      height: 44px;
      line-height: 44px;
      margin: 10px 0;
      font-size: 15px;
      list-style: none;

      img {
        vertical-align: middle;
        margin: -3px 8px 0 0;
        width: 20px;
        position: absolute;
        left: -1000px;
        filter: drop-shadow(var(--color) 1023px 13px) !important;
      }

      &.active,
      &:hover {
        cursor: pointer;
        background-color: #f8f8f8;
      }
    }
  }

  .camp_right {
    flex: 1;
    // width: calc(100% - 100px);
    // height: 100%;
    // overflow: auto;
    border-radius: 8px;
    background-color: #ffffff;
  }
}
</style>
